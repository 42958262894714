import React from 'react';
import Typography from 'components/Typography';
// import createDOMPurify from 'dompurify';
// import { JSDOM } from 'jsdom';

// const { window } = new JSDOM('');
// const { sanitize } = createDOMPurify(window);

// disable DOMPurify as it's breaking build
const sanitize = (string: string) => string;

interface Props {
  html: string,
  className?: string,
  as?: React.ElementType,
}
const SafeHtml:React.FC<Props> = ({
  html,
  className,
  as: Element = (props) => (
    <Typography {...props} variant="container" />
  ),
}) => (
  <Element
    dangerouslySetInnerHTML={{ __html: sanitize(html) }}
    className={`user-content ${className || ''}`}
  />
);
export default SafeHtml;

import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from 'lib/components/Seo';
import Container from 'src/components/Container';
import Block from 'components/Block';
import styled from 'styled-components';
import PageHeader from 'src/components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import isNotNull from 'src/types/guards/isNotNull';
import useCompanyBenefits from 'src/hooks/queries/useCompanyBenefits';
import CareerQuery_Career from 'src/transformers/CareerQuery_Career';
import Typography from 'src/components/Typography';
import { resolveUrl } from 'src/helpers';
import GenericGridBlock from 'src/components/GenericGridBlock';
import TextTile from 'src/components/tiles/TextTile';
import { nanoid } from 'nanoid';
import Spacer from 'src/components/Spacer';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';

export const query = graphql`
  query CareersPage($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      ...BasicPageBasic
    }
    allWpCareer(sort: {order: ASC, fields: menuOrder}) {
      nodes {
        ...CareerWithThumbnail
      }
    }
  }
`;


interface Props {
  data: GatsbyTypes.CareersPageQuery,
}


const CareersMultiPage:React.FC<Props> = (props) => {
  const { data } = props;
  const companyBenefits = useCompanyBenefits();
  const companyBenefitsWithIds = companyBenefits?.map((benefit) => ({
    ...benefit,
    id: nanoid(),
  })) || [];
  const pageData = BasicQuery_SitePage(data.wpPage || {});
  const breadcrumbs: BreadcrumbsProps['items'] = [{ label: pageData.navTitle }];

  const careersRaw = data.allWpCareer.nodes;
  const careers = Array.from(careersRaw, CareerQuery_Career).filter(isNotNull);

  return (
    <CaseStudiesPageOuter>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
      />
      <PageHeader
        h1={pageData.h1}
        backgroundText={pageData.contentHtml}
      />
      <Container readableWidth>
        <Breadcrumbs items={breadcrumbs} />
        {pageData.contentHtml && (
          <Block>
            <ContentParser>{pageData.contentHtml}</ContentParser>
          </Block>
        )}
      </Container>

      <Block
        id="vacancies-block"
        darkBg
        contain
        title="Vacancies."
        customConfig={{ py: 5 }}
      >
        {careers.length > 0 ? (
          careers.map((career) => (
            <CareerRow key={career._id}>
              <Link to={resolveUrl.career(career.slug)}>
                <Typography variant="h3" as="p" color="white">{career.title}</Typography>
              </Link>
            </CareerRow>
          ))
        ) : (
          // eslint-disable-next-line max-len
          <Typography>We don&apos;t have any vacancies right now, but watch this space</Typography>
        )}
        <Spacer mb={3} />
      </Block>

      {companyBenefitsWithIds?.length > 0 && (
        <Block contain>
          <GenericGridBlock title="Our Benefits.">
            {companyBenefitsWithIds.map((benefit) => (
              <TextTile
                key={benefit.id}
                copyHTML={benefit.description || ''}
                title={benefit.name || ''}
                icon={benefit.icon?.localFile?.childImageSharp?.gatsbyImageData}
              />
            ))}
          </GenericGridBlock>
        </Block>
      )}
    </CaseStudiesPageOuter>
  );
};
export default CareersMultiPage;

const CaseStudiesPageOuter = styled.div``;
const CareerRow = styled.div``;

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import SafeHtml from 'lib/components/SafeHtml';
import Button from 'components/Button';
import Spacer from 'components/Spacer';

export interface Props {
  title: string,
  copyHTML: string,
  href?: string,
  ctaText?: string,
  icon?: IGatsbyImageData,
}

const TextTile:React.FC<Props> = ({
  title,
  copyHTML,
  href,
  ctaText = 'View more',
  icon,
}) => (
  <TextTileOuter>
    <IconTextWrap>
      {icon && (
        <IconWrap mb={2}>
          <GatsbyImage image={icon} alt={title} />
        </IconWrap>
      )}
      <Typography variant="h4" as="p">{title}</Typography>
      <Spacer mb={1} />
      <SafeHtml html={copyHTML} />
      <Spacer mb={1.5} />
    </IconTextWrap>
    {href && (
      <ButtonWrap>
        <Button href={href} type="secondary">{ctaText}</Button>
      </ButtonWrap>
    )}
  </TextTileOuter>
);
export default TextTile;

const TextTileOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  p { margin: 0; }
`;

const IconTextWrap = styled.div``;
const IconWrap = styled(Spacer)`
  width: 40px;
`;
const ButtonWrap = styled.div``;

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Career, EmploymentType } from 'models/Career';

type BasicRepeaterObject = { item: string };

interface RequiredInput {
  [key:string]: any,
  id?: string,
  title?: string,
  slug?: string,
  content?: string,
  featuredImage?: {
    node?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData,
        },
      },
    },
  },
  acf?: {
    dateClosing?: string,
    datePosted?: string,
    startDate?: string,
    employmentType?: string,
    isFullyRemote?: boolean,
    requiredYearsOfExperience?: number,
    salaryMinimum?: number,
    salaryMaximum?: number,
    responsibilities?: BasicRepeaterObject[],
    essentialPoints?: BasicRepeaterObject[],
    desirablePoints?: BasicRepeaterObject[],
    numberOfVacancies?: number,
  }
}

const extractItem = (obj: BasicRepeaterObject): string => obj.item;
const getDateFromString = (input?: string): Date | undefined => {
  if (typeof input === 'string') return new Date(input);
  return undefined;
};

export default (
  queryData?: RequiredInput,
): Career | null => {
  if (!queryData) return null;
  return new Career(
    queryData.title || '',
    queryData.slug || '',
    queryData.content || '',
    queryData?.acf?.responsibilities?.map(extractItem) || [],
    queryData?.acf?.essentialPoints?.map(extractItem) || [],
    queryData?.acf?.desirablePoints?.map(extractItem) || [],
    getDateFromString(queryData?.acf?.datePosted) || new Date(),
    getDateFromString(queryData?.acf?.dateClosing),
    getDateFromString(queryData?.acf?.startDate),
    {
      minimum: queryData?.acf?.salaryMinimum,
      maximum: queryData?.acf?.salaryMaximum,
    },
    queryData.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    queryData?.acf?.employmentType as EmploymentType,
    queryData?.acf?.numberOfVacancies,
    queryData?.acf?.requiredYearsOfExperience,
    queryData?.acf?.isFullyRemote,
  );
};

import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.CompanyBenefitsSingleFragment['companyBenefits'] => {
  const result = useStaticQuery<GatsbyTypes.CompanyBenefitsQuery>(graphql`
    fragment CompanyBenefitsSingle on WpSiteSettings_Acf {
      companyBenefits {
        name
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    query CompanyBenefits {
      allWp {
        nodes {
          siteSettings {
            acf {
              ...CompanyBenefitsSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].siteSettings?.acf?.companyBenefits || [];
};

import React from 'react';
import styled from 'styled-components';
import TileGrid, { Props as TileGridProps } from 'components/TileGrid';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';

export type Props = TileGridProps & {
  title?: string,
  smallTitle?: boolean,
};

const GenericGridBlock:React.FC<Props> = ({ title, smallTitle, ...restProps }) => {
  return (
    <GenericGridBlockOuter>
      {title && (
        <Spacer pb={4}>
          <Typography variant={smallTitle ? 'h2' : 'hero'} as="h3">{title}</Typography>
        </Spacer>
      )}

      <TileGrid {...restProps} />
    </GenericGridBlockOuter>
  );
};
export default GenericGridBlock;

const GenericGridBlockOuter = styled.div``;

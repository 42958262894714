import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';

export type EmploymentType =
| 'FULL_TIME'
| 'PART_TIME'
| 'CONTRACTOR'
| 'TEMPORARY'
| 'INTERN'
| 'VOLUNTEER'
| 'PER_DIEM'
| 'OTHER';

export class Career extends Base {
  constructor(
    public title: string,
    public slug: string,
    public description: string,
    public responsibilities: string[],
    public essentialPoints: string[],
    public desirablePoints: string[],
    public datePosted: Date,
    public dateClosing?: Date,
    public startDate?: Date,
    public salary?: {
      minimum?: number,
      maximum?: number,
    },
    public image?: IGatsbyImageData,
    public employmentType: EmploymentType = 'FULL_TIME',
    public numberOfVacancies: number = 1,
    public requiredYearsOfExperience?: number,
    public isFullyRemote?: boolean,
  ) { super(); }
}
